<template>
  <div class="products">
    <div class="product-header" :class="{ active: filtered }">
      <Filtrele :filter="filter" @filtered="handlerTemizle" @handlerFilter="handlerFilter" />
    </div>
    <div class="product-body">
      <b-alert v-if="!rows.length" show variant="warning" class="text-center m-2">
        <h5>Bilgi</h5>
        <hr />
        <p>Gösterilecek veri yok</p>
      </b-alert>
      <vue-good-table
        v-else
        :columns="columns"
        :rows="rows"
        :line-numbers="true"
        :pagination-options="{
          enabled: false,
          perPage: 1000,
        }"
        styleClass="vgt-table striped bordered"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'urun_k_no'">
            <router-link
              :to="{
                name: 'urun-hareketler',
                params: { k_no: props.row.k_no },
              }"
            >
              <span class="d-block text-dark font-weight-bold">{{ props.row.k_no }}</span>
            </router-link>
          </span>

          <span v-else-if="props.column.field === 'urun_adi'">
            {{ props.row.urun_adi[defaultDil].baslik }}
          </span>
          <span v-else-if="props.column.field === 'toplam_adet'">
            <h5>
              <b-badge variant="info">{{ props.row.cikis }}</b-badge>
            </h5>
          </span>
          <span v-else-if="props.column.field === 'action'" class="float-right px-1">
            <b-button-group size="sm" class="pb-25">
              <b-button
                class="rounded-0"
                variant="primary"
                v-b-tooltip.hover.top="'Ürün Hareketleri'"
                :to="{
                  name: 'urun-hareketler',
                  params: { k_no: props.row.k_no },
                }"
              >
                <i class="fad fa-eye" />
              </b-button>
            </b-button-group>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </div>
  </div>
</template>
<script>
import store from '@/store';
import Swal from 'sweetalert2';
import vSelect from 'vue-select';
import { VueGoodTable } from 'vue-good-table';
import { useToast } from 'vue-toastification/composition';
import BTableCard from '@/components/cards/BTableCard.vue';
import { defineComponent, ref, computed, watch, toRefs } from '@vue/composition-api';
import axiosIns from '@/libs/axios';
import Filtrele from './component/Filtrele';
import moment from 'moment';
export default defineComponent({
  components: {
    vSelect,
    BTableCard,
    VueGoodTable,
    BTableCard,
    Filtrele,
  },
  props: {
    filtered: [Boolean],
  },
  setup(props, context) {
    const expo = {};
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);

    expo.count = ref(0);
    expo.filter = ref({
      baslama_tarih: moment().format('YYYY-MM-DD'),
      bitis_tarih: moment().format('YYYY-MM-DD'),
      siralama: 'artan', // artan, azalan
    });
    expo.columns = ref([
      {
        label: 'Ürün Kodu',
        field: 'urun_k_no',
        width: '20%',
      },
      {
        label: 'Ürün Adı',
        field: 'urun_adi',
      },
      {
        label: 'Toplam Adet',
        field: 'toplam_adet',

        thClass: 'text-right',
        tdClass: 'text-right',
      },
      {
        label: 'İşlemler',
        field: 'action',
        width: '12%',
        thClass: 'text-right',
        tdClass: 'text-right',
      },
    ]);
    expo.rows = ref([]);

    const handlerFetchAllData = async () => {
      context.emit('show', true);
      await store.dispatch('urunSatisRaporuListele', expo.filter.value).then((res) => {
        if (res.data.success) {
          expo.rows.value = store.getters.getUrunSatisRapor;
          context.emit('total', expo.rows.value.length);
        }
      });
      context.emit('show', false);
    };
    handlerFetchAllData();

    expo.handlerFilter = () => {
      handlerFetchAllData();
      context.emit('filtered', false);
    };

    expo.handlerTemizle = () => {
      expo.filter.value = {
        baslama_tarih: moment().format('YYYY-MM-DD'),
        bitis_tarih: moment().format('YYYY-MM-DD'),
        siralama: 'artan', // artan, azalan
      };
      context.emit('filtered', false);
      handlerFetchAllData();
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
table.vgt-table {
  font-size: 14px;
  thead {
    background-color: rgba(#000, 0.07);
  }
  td {
    padding: 3px 0px 0px 5px !important;
    vertical-align: middle !important;
  }
}

.products {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  .product-header {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ebe9f1;
    padding: 10px 5px;
    background: #fff;
    transition: all 0.2s ease-in-out;
    z-index: 5;
    &.active {
      opacity: 1;
      visibility: visible;
      transition: all 0.2s ease-in-out;
    }
  }
}
</style>
